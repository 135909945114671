import classNames from 'classnames';

import useTheme, { THEME } from '@/hooks/useTheme';
import SearchOutlineIcon from '../../assets/svg/search_outline.svg';

function OpenSearchButton({ onClick }) {
  const { theme } = useTheme();

  return (
    <button
      type="button"
      className={classNames('hover:opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white', {
        'text-primary': theme === THEME.DEFAULT,
        'text-secondary-tamula': theme === THEME.CHRISTMAS2022,
      })}
      onClick={onClick}
    >
      <span className="sr-only">Ava otsing</span>
      <SearchOutlineIcon className="h-12 w-12" />
    </button>
  );
}

export default OpenSearchButton;
