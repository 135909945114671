import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import LoadingSVG from '../assets/svg/loading.svg';
import useTheme, { THEME } from '@/hooks/useTheme';

function Button(
  {
    children,
    type = 'button',
    variant = 'primary',
    size = 'medium',
    textTransform = 'uppercase',
    loading,
    disabled,
    className,
    to,
    padding,
    ...props
  },
  ref,
) {
  const { theme } = useTheme();

  const classNamesMap = classNames(
    'inline-flex items-center justify-center text-center font-bold rounded-full hover:opacity-70 whitespace-nowrap [&>*]:pointer-events-none focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-current',
    className,
    {
      ...(theme === THEME.DEFAULT && {
        'border-transparent bg-primary-haapsalu hover:bg-primary text-white': variant === 'primary' && !disabled,
        'bg-transparent border-primary-haapsalu hover:border-primary text-primary-haapsalu hover:text-primary':
          variant === 'secondary' && !disabled,
        'border-transparent bg-secondary-lahemaa text-white': variant === 'buy' && !disabled,
        'bg-transparent border-transparent text-black': variant === 'ghost' || variant === 'ghost-dark',
      }),
      ...(theme === THEME.CHRISTMAS2022 && {
        'border-transparent bg-secondary-tamula text-national-park-vilsandi':
          (variant === 'primary' || variant === 'buy') && !disabled,
        'bg-transparent border-secondary-tamula text-secondary-tamula': variant === 'secondary' && !disabled,
        'bg-national-park-vilsandi border-national-park-vilsandi text-secondary-tamula': variant === 'dark',
        'bg-transparent border-transparent text-secondary-tamula': variant === 'ghost',
        'bg-transparent border-transparent text-black': variant === 'ghost-dark',
      }),
      'bg-gray-pahkla border-gray-pahkla text-primary-haapsalu': variant === 'tertiary',
      'bg-transparent border-transparent text-current text-bold': variant === 'link-bold',
      'text-[0.625rem] h-6 leading-[0.625rem] border': size === 'tiny',
      'text-xs h-8 border-2': size === 'small',
      'text-xs h-10 border-2': size === 'medium',
      'text-sm h-12 border-2': size === 'large',
      uppercase: textTransform === 'uppercase',
      'cursor-default': disabled,
      'border-transparent bg-gray-majakivi hover:bg-gray-majakivi active:bg-gray-majakivi text-white':
        disabled && (variant === 'primary' || variant === 'buy'),
      'bg-transparent border-gray-majakivi hover:border-gray-majakivi active:border-gray-majakivi text-gray-majakivi':
        disabled && variant === 'secondary',
    },
  );

  const innerClassNamesMap = classNames('inline-flex items-center justify-center text-center', {
    'py-1 px-3': size === 'tiny' && padding !== 'none',
    'py-2 px-4': size === 'small' && padding !== 'none',
    'py-3 px-5': size === 'medium' && padding !== 'none',
    'py-4 px-6': size === 'large' && padding !== 'none',
    'p-0': padding === 'none',
  });

  const Tag = to ? Link : props.href ? 'a' : 'button';
  const linkProps = {
    ...(to && {
      href: to,
    }),
  };
  const buttonProps = {
    ...(!to && {
      type,
      className: classNamesMap,
    }),
    ...(props.href && {
      type: null,
    }),
  };
  const InnerComponent = to ? 'a' : React.Fragment;
  const innerComponentProps = {
    ...(to && {
      className: classNamesMap,
    }),
  };

  return (
    <Tag className={classNamesMap} disabled={disabled} {...props} {...linkProps} {...buttonProps}>
      <InnerComponent {...innerComponentProps}>
        <span ref={ref} className={innerClassNamesMap}>
          {loading && <LoadingSVG className="h-6 w-6 animate-spin" />}
          <span className={classNames({ 'ml-2': loading })}>{children}</span>
        </span>
      </InnerComponent>
    </Tag>
  );
}

export default forwardRef(Button);
