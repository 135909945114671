import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

function LanguageSwitcher({ uri, className, closeMobileMenu }) {
  const t = useTranslations();
  const router = useRouter();

  function handleMobileMenuClose() {
    if (typeof closeMobileMenu === 'function') {
      closeMobileMenu();
    }
  }

  return (
    <ul className={classNames('flex', className)}>
      {Object.entries(uri).map(([locale, url]) => (
        <li key={locale}>
          <Link href={url} locale={locale}>
            <a
              className={classNames('px-4 md:px-2 py-2 text-sm hover:underline underline-offset-2', {
                underline: router.locale === locale,
              })}
              onClick={handleMobileMenuClose}
            >
              {t(`LANGUAGE_${locale.toUpperCase()}`)}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default LanguageSwitcher;
