import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, MenuAlt2Icon } from '@heroicons/react/outline';

import Navigation from './Navigation';
import Logo from './Logo';
import useTheme, { THEME } from '@/hooks/useTheme';
import classNames from 'classnames';

function MobileMenu({ children }) {
  const { theme } = useTheme();
  const [open, setOpen] = useState(false);

  function close() {
    setOpen(false);
  }

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="md:hidden focus:outline-none focus-visible:ring-2 focus-visible:ring-white"
      >
        <span className="sr-only">Ava menüü</span>
        <MenuAlt2Icon className="h-6 w-6" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={classNames('relative flex-1 flex flex-col max-w-xs w-full pb-4', {
                'bg-white': theme === THEME.DEFAULT,
                'bg-national-park-vilsandi': theme === THEME.CHRISTMAS2022,
              })}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>

              <Logo className="px-4" />

              <div className="mt-5 flex-1 h-0 overflow-y-auto space-y-8">
                <Navigation direction="column" closeMobileMenu={close} />
                {React.cloneElement(children, { closeMobileMenu: close })}
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default MobileMenu;
