import Head from 'next/head';
import { useRouter } from 'next/router';

const SEO = ({ meta, uri }) => {
  const router = useRouter();
  const url = `${process.env.NEXT_PUBLIC_WEBSITE_URL}${uri?.[router.locale]}`;

  return (
    <Head>
      <title>{meta?.title || 'Puhka Eestis'}</title>
      <meta content={meta?.metaDesc} name="description" />
      <link rel="canonical" href={url} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={meta?.opengraphType} />
      <meta property="og:site_name" content={meta?.title} />
      <meta property="og:description" content={meta?.metaDesc} />
      <meta property="og:title" content={meta?.opengraphTitle} />
      <meta property="og:image" content={meta?.opengraphImage?.mediaItemUrl} />
      <meta property="og:image:height" content={meta?.opengraphImage?.mediaDetails?.height} />
      <meta property="og:image:width" content={meta?.opengraphImage?.mediaDetails?.width} />
      <meta property="og:locale" content={router.locale} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={meta?.twitterTitle} />
      <meta name="twitter:description" content={meta?.twitterDescription} />
      <meta name="twitter:image" content={meta?.twitterImage?.mediaItemUrl} />

      {router.locales.map((locale) => {
        return (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${uri?.[locale]}`}
          />
        );
      })}
    </Head>
  );
};

export default SEO;
