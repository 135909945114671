export const xssWhitelist = () => ({
  whiteList: {
    a: ['href', 'title', 'target', 'rel'],
    em: [],
  },
});

export const isBoolean = (val) => typeof val === 'boolean';
export const isNumber = (val) => !isNaN(parseFloat(val)) && isFinite(val);

export function hasValue(value) {
  return isBoolean(value) || isNumber(value) || !!value;
}

export const formatUrl = (urlString) => {
  try {
    const correctUrl =
      urlString.startsWith('http') || urlString.startsWith('https') ? urlString : `https://${urlString}`;
    const url = new URL(correctUrl);

    return { hostname: url.hostname, href: url.href };
  } catch {
    return '';
  }
};

export const getAddress = ({ address, translate }) => {
  if (!address) {
    return null;
  }

  const streetBuilding = [address.street, address.building, address.appartment].filter(Boolean).join(' ');

  return [streetBuilding, address.zip, address.settlement, address.city, translate(address.county)]
    .filter(Boolean)
    .join(', ');
};

export function getImage(object) {
  return (
    object?.multimediaGroup?.multimedia?.find((media) => media.frontPic === 'true') ||
    object?.multimediaGroup?.multimedia?.[0]
  );
}

export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};
