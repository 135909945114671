import { useTranslations } from 'next-intl';
import classNames from 'classnames';

import CloseIcon from '../../assets/svg/close.svg';
import SearchIcon from '../../assets/svg/search.svg';
import LoadingSVG from '../../assets/svg/loading.svg';
import Button from '../Button';

function SearchInput({ value, onChange, onClose, isLoading, isError }) {
  const t = useTranslations();

  return (
    <div
      className={classNames(
        'grid grid-cols-[min-content_auto_min-content] items-center gap-x-4 w-full h-12 rounded-[3.125rem] bg-white text-black border-2 px-[1.5625rem]',
        {
          'border-secondary-toila': isError,
          'border-primary': !isError,
        },
      )}
    >
      {isLoading ? (
        <LoadingSVG className="h-5 w-5 text-primary animate-spin" />
      ) : (
        <SearchIcon
          className={classNames('h-5 w-5', {
            'text-secondary-toila': isError,
            'text-primary': !isError,
          })}
        />
      )}
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="text-lg md:text-base focus:outline-none"
        name="searchText"
        placeholder={t('SEARCH_PLACEHOLDER')}
      />
      <Button variant="ghost-dark" padding="none" onClick={onClose}>
        <span className="sr-only">Sulge otsing</span>
        <CloseIcon className="h-4 w-4" />
      </Button>
    </div>
  );
}

export default SearchInput;
