import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import { useTranslations } from 'next-intl';
import classNames from 'classnames';

import Header from '@/components/Header/Header';
// import EULogo from '@/components/EULogo';
import Search from '@/components/Search/Search';
import { useState } from 'react';
function Layout({ children, pageTitle, uri, withoutPadding, withoutOverflow }) {
  const [showSearch, setShowSearch] = useState(false);
  const t = useTranslations();

  return (
    <>
      <h1 className="sr-only">{pageTitle || t('PAGE_TITLE')}</h1>
      <SkipNavLink />
      <div className="flex flex-col min-h-screen pt-20 md:pt-[6.25rem]">
        <Header uri={uri} onShowSearch={() => setShowSearch(true)} />
        <SkipNavContent className="flex-1">
          <main
            role="main"
            className={classNames('pt-4 md:pt-8', {
              'pb-24': !withoutPadding,
              'overflow-x-hidden': !withoutOverflow,
            })}
          >
            {children}
          </main>
        </SkipNavContent>
      </div>
      {/* <EULogo /> */}
      <Search isOpen={showSearch} onClose={() => setShowSearch(false)} />
    </>
  );
}

export default Layout;
