export function meta(t, { title, description, image } = {}) {
  const titleString = title ? `${title} — ${t('PAGE_DEFAULT_TITLE')}` : t('SEO_TITLE');
  const descriptionString = description || t('SEO_DESCRIPTION');
  const imageString = image || 'https://kingiaega.puhkaeestis.ee/images/cover-christmas-2022.jpg';

  return {
    title: titleString,
    opengraphTitle: titleString,
    twitterTitle: titleString,
    metaDesc: descriptionString,
    twitterDescription: descriptionString,
    opengraphType: 'article',
    opengraphImage: {
      mediaItemUrl: imageString,
      mediaDetails: {
        height: 628,
        width: 1200,
      },
    },
    twitterImage: {
      mediaItemUrl: imageString,
    },
  };
}
