import { DialogContent, DialogOverlay } from '@reach/dialog';
import classNames from 'classnames';

import Container from '@/components/Container';
import useTheme, { THEME } from '@/hooks/useTheme';

function Overlay({ children, className, isOpen, onClose }) {
  const { theme } = useTheme();

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onClose}
      className={classNames('fixed left-0 right-0 top-0 bottom-0 z-50', className, {
        'bg-secondary-liivi/[.76]': theme === THEME.DEFAULT,
        'bg-national-park-vilsandi/[.86]': theme === THEME.CHRISTMAS2022,
      })}
    >
      <Container>
        <DialogContent aria-label="Otsingu tulemused">{children}</DialogContent>
      </Container>
    </DialogOverlay>
  );
}

export default Overlay;
