import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';

import routes from '@/lib/routes';
import MyLink from './MyLink';

function Navigation({ direction, className, closeMobileMenu }) {
  const t = useTranslations();
  const router = useRouter();
  const currentPath =
    router.locale === router.defaultLocale
      ? router.pathname
      : router.pathname === '/'
      ? `/${router.locale}`
      : `/${router.locale}${router.pathname}`;
  const navigation = [
    {
      id: 1,
      href: routes.HOME[router.locale],
      title: t('MENU_HOME'),
    },
    {
      id: 4,
      href: '/#gift-generator',
      title: t('MENU_GIFT_GENERATOR'),
    },
    {
      id: 3,
      href: '/#how-is-it-working',
      title: t('MENU_HOW_IS_IT_WORKING'),
    },
    {
      id: 2,
      href: routes.FILTER[router.locale],
      title: t('MENU_FILTER'),
    },
    {
      id: 5,
      href: t('DETAIL_CAMPAIGN_TERMS_URL'),
      title: t('DETAIL_CAMPAIGN_TERMS'),
      isExternal: true,
    },
  ];

  function handleMobileMenuClose() {
    if (typeof closeMobileMenu === 'function') {
      closeMobileMenu();
    }
  }

  return (
    <nav className={classNames(className)}>
      <h2 className="sr-only">Navigatsioon</h2>
      <ul
        className={classNames('flex md:-ml-4', {
          'flex-col': direction === 'column',
        })}
      >
        {navigation.map((item) => (
          <li key={item.id}>
            {item.isExternal ? (
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="block px-4 py-2 text-sm underline-offset-2 hover:underline"
                href={item.href}
              >
                {item.title}
              </a>
            ) : (
              <MyLink href={item.href} isActive={currentPath === item.href} onClick={handleMobileMenuClose}>
                {item.title}
              </MyLink>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navigation;
