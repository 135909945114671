import classnames from 'classnames';

function Container({ children, tag, id, className, size = 'medium', noPadding }) {
  const Tag = tag || 'div';

  return (
    <Tag
      className={classnames(
        'mx-auto my-0 w-full',
        {
          'px-4': !noPadding,
          'max-w-[35.625rem]': size === 'small',
          'max-w-[75rem]': size === 'medium',
        },
        className,
      )}
      id={id}
    >
      {children}
    </Tag>
  );
}

export default Container;
