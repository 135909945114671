import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import SearchInput from '@/components/Search/SearchInput';
import SearchResults from '@/components/Search/SearchResults';
import Overlay from './Overlay';
import BackendClient from '@/lib/api';
import { useDebounce } from 'react-use';
import { useRouter } from 'next/router';

const MIN_SEARCH_LENGTH = 2;

function Search({ isOpen, onClose }) {
  const router = useRouter();
  const [value, setValue] = useState('');
  const [searchText, setSearchText] = useState('');

  useDebounce(
    () => {
      setSearchText(value);
    },
    300,
    [value],
  );

  const {
    data: resultsData,
    isLoading,
    isError,
  } = useQuery(['searchText', searchText], () => {
    if (searchText.length > MIN_SEARCH_LENGTH) {
      return BackendClient.searchSellerObjects(searchText, router.locale);
    }

    return undefined;
  });

  const resetValues = useCallback(() => {
    setValue('');
    setSearchText('');
  }, [setValue, setSearchText]);

  useEffect(() => {
    if (isOpen) {
      resetValues();
    }
  }, [isOpen, resetValues]);

  return (
    <Overlay className="pt-5" isOpen={isOpen} onClose={onClose}>
      <div className="space-y-4">
        <SearchInput value={value} onChange={setValue} onClose={onClose} isLoading={isLoading} isError={isError} />
        {resultsData?.data && <SearchResults results={resultsData.data} onOpenLink={onClose} />}
      </div>
    </Overlay>
  );
}

export default Search;
