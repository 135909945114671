const routes = {
  HOME: {
    et: '/',
    ru: '/ru',
  },
  DETAIL: {
    et: '/objekt',
    ru: '/ru/obyekt',
  },
  FILTER: {
    et: '/filter',
    ru: '/ru/filter',
  },
  ORDER: {
    et: '/tellimus',
    ru: '/ru/zakaz',
  },
  CONFIRMATION: {
    et: '/kinnitus',
    ru: '/ru/podtverzhdeniye',
  },
};

export default routes;
