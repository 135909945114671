import classNames from 'classnames';

import Container from '../Container';
import LanguageSwitcher from './LanguageSwitcher';
import Logo from './Logo';
import MobileMenu from './MobileMenu';
import Navigation from './Navigation';
import OpenSearchButton from '@/components/Header/OpenSearchButton';
import useTheme, { THEME } from '@/hooks/useTheme';

function Header({ uri, onShowSearch }) {
  const { theme } = useTheme();

  return (
    <header
      className={classNames('fixed top-0 left-0 right-0 z-10 bg-opacity-40 backdrop-filter backdrop-blur-sm', {
        'bg-white': theme === THEME.DEFAULT,
        'bg-national-park-vilsandi': theme === THEME.CHRISTMAS2022,
      })}
    >
      <Container className="flex items-center justify-between">
        <Navigation className="hidden md:block" />
        <MobileMenu>
          <LanguageSwitcher uri={uri} />
        </MobileMenu>
        <div className="ml-auto flex items-center space-x-4">
          <OpenSearchButton onClick={onShowSearch} />
          <LanguageSwitcher uri={uri} className="hidden md:flex" />
          <Logo />
        </div>
      </Container>
    </header>
  );
}

export default Header;
