import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import classNames from 'classnames';

import routes from '@/lib/routes';
import { getImage } from '@/lib/utils';
import placeholder from '@/assets/images/placeholder.jpg';
import Heading from '@/components/Heading';
import useTheme, { THEME } from '@/hooks/useTheme';

function SearchResults({ results, onOpenLink }) {
  const router = useRouter();
  const t = useTranslations();
  const { theme } = useTheme();

  if (!results.length) {
    return (
      <div
        className={classNames(
          'p-4 sm:p-6 overflow-y-auto space-y-6 max-h-[31.25rem]',
          {
            'bg-white': theme === THEME.DEFAULT,
            'bg-secondary-tamula': theme === THEME.CHRISTMAS2022,
          },
          'text-sm',
        )}
      >
        {t('SEARCH_NOT_FOUND')}
      </div>
    );
  }

  return (
    <ul
      className={classNames('p-4 sm:p-6 overflow-y-auto space-y-6 max-h-[31.25rem]', {
        'bg-white': theme === THEME.DEFAULT,
        'bg-secondary-tamula': theme === THEME.CHRISTMAS2022,
      })}
    >
      {results.map((object) => {
        const image = getImage(object)?.url ?? placeholder;
        const url = `${routes.DETAIL[router.locale]}/${object.slugs[router.locale]}`;
        const tags = [t(object.position?.address?.county), object.position?.address?.city].filter(Boolean);

        return (
          <li key={object._id}>
            <Link href={url}>
              <a onClick={onOpenLink}>
                <div
                  className={classNames('grid grid-cols-[min-content_auto] gap-4 hover:p-[0.625rem] transition-all', {
                    'hover:bg-gray-pahkla': theme === THEME.DEFAULT,
                    'hover:bg-secondary-soomaa': theme === THEME.CHRISTMAS2022,
                  })}
                >
                  <figure className="relative min-h-[4.5rem] min-w-[4.5rem] sm:h-full col-span-1">
                    <Image
                      src={image}
                      layout="fill"
                      objectFit="cover"
                      loading="lazy"
                      alt={object.title[router.locale]}
                    />
                  </figure>
                  <div className="pt-2 space-y-2">
                    <Heading
                      level="h3"
                      visualLevel="h5"
                      weight="bold"
                      color={theme === THEME.DEFAULT ? null : 'national-park-vilsandi'}
                    >
                      {object.title[router.locale]}
                    </Heading>
                    <div className="flex flex-wrap">
                      {tags.map((tag, index) => (
                        <p
                          key={index}
                          className={classNames('text-xs text-gray-secondary pr-2 mb-1', {
                            'border-l': index !== 0,
                            'pl-2': index !== 0,
                          })}
                        >
                          {tag}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default SearchResults;
