import Link from 'next/link';
import classNames from 'classnames';

function MyLink({ href, locale, isActive, children, ...rest }) {
  return (
    <Link href={href} locale={locale}>
      <a
        className={classNames('block px-4 py-2 text-sm underline-offset-2 hover:underline', {
          'underline font-bold': isActive,
        })}
        {...rest}
      >
        {children}
      </a>
    </Link>
  );
}

export default MyLink;
